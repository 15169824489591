<template>
  <div id="express-three-number">
    <div class="row">
        <div class="col">
          <button class="btn mb-2 mx-1 btn-pp" v-for="(number, index) in inputNumbers" @click="toggleRemove(index)">{{number}}</button>
        </div>
      <div class="col-auto">
        <button type="button" class="btn btn-danger btn-sm" @click="clearNumbers"><i class="fas fa-trash-alt mr-1"></i>ยกเลิก</button><br />
        <button type="button" class="btn btn-primary btn-sm mt-1" @click="uniqueNumbers"><i class="fas fa-ban mr-1"></i>ลบเลขซ้ำ</button>
      </div>
    </div>

    <div class="row">
      <div class="col-12 mt-1">
        <button type="button" class="btn shadow-sm btn-warning btn-sm" @click="toggleTripleNumber"><i class="fas fa-plus"></i> เลขตอง</button>
      </div>
    </div>

    <div ref="threeNumber" class="form-group row mt-1 mb-3">
      <div class="col pr-1">
        <label for="" class="small mb-1">ใส่เลข</label>
        <b-form-input
          type="text"
          class="form-control express"
          name="inputNumber"
          v-on:keydown="keypress($event, 'num')"
          v-model="inputNumber"
          inputmode="numeric"
          pattern="[0-9]*"
        />
      </div>
      <div class="col-auto px-1 align-self-end">
        <button class="btn btn-warning" @click="toggleSpin">กลับเลข</button>
      </div>
      <div class="col px-1 text-center">
        <label for="" class="small mb-1">บน</label>
        <b-form-input
          type="text"
          class="text-center form-control express"
          name="amountTop"
          v-on:keydown="keypress($event, 'top')"
          v-model="amountTop"
          :disabled="!isOpenThreeTop"
          :placeholder="!isOpenThreeTop?'ไม่เปิดแทง':''"
          :number="true"
          inputmode="numeric"
          pattern="[0-9]*"
        />
      </div>
      <div class="col px-1 text-center">
        <label for="" class="small mb-1">โต๊ด</label>
        <b-form-input
          type="text"
          class="text-center form-control express"
          name="amountTod"
          v-on:keydown="keypress($event, 'bot')"
          v-model="amountTod"
          :disabled="!isOpenThreeTod"
          :placeholder="!isOpenThreeTod?'ไม่เปิดแทง':''"
          :number="true"
          inputmode="numeric"
          pattern="[0-9]*"
        />
      </div>
      <div class="col-auto pl-1 mt-2 text-center align-self-end">
        <button type="button" class="btn btn-info express" v-on:keydown="keypress" @click="addBill"><i class="fas fa-plus mt-1"></i> เพิ่มบิล</button>
      </div>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
  name: 'ThreeNumber',
  data() {
    return {
      elements: null,
      inputNumber: '',
      amountTop: null,
      amountTod: null,
      inputNumbers: []
    }
  },
  computed: {
    rate() {
      return this.$store.state.round.rate
    },
    isOpenThreeTop() {
      return this.rate?.openBets.threeNumberTop.isAvailable || false
    },
    isOpenThreeTod() {
      return this.rate?.openBets.threeNumberTode.isAvailable || false
    },
    isOpenTwoTop() {
      return this.rate?.openBets.twoNumberTop.isAvailable || false
    },
    isOpenTwoBot() {
      return this.rate?.openBets.twoNumberBottom.isAvailable || false
    },
    isOpenRunTop() {
      return this.rate?.openBets.runTop.isAvailable || false
    },
    isOpenRunBot() {
      return this.rate?.openBets.runBottom.isAvailable || false
    }
  },
  watch: {
    inputNumber(n, o) {
      if(n.length === 3) {
        if(n.replace(/[^0-9]/g, '').length === 3) {
          this.addNumber(n)
        }
        setTimeout(()=>{
          this.inputNumber = ''
        }, 100)
      }else
      if(n.length > 3) {
        const numbers = n.replace(/[^0-9]/g, ' ')
          .split(' ')
          .filter(n => n.trim().length === 3)

        this.addNumbers(numbers)
        setTimeout(()=>{
          this.inputNumber = ''
        }, 100)
      }
    },
    inputNumbers(n, o) {
      this.$emit('inputNumbers', n)
    }
  },
  methods: {
    addNumbers(numbers) {
      this.inputNumbers = this.inputNumbers.concat(numbers)
    },
    addNumber(number) {
      // if(!this.inputNumbers.includes(number))
      this.inputNumbers.push(number)
    },
    addNumbers(numbers) {
      numbers.forEach((number)=>{
        this.addNumber(number)
      })
    },
    toggleSpin() {
      this.inputNumbers.forEach((number)=>{
        const n = number.split('')
        _.uniq([
          `${n[0]}${n[1]}${n[2]}`,
          `${n[0]}${n[2]}${n[1]}`,
          `${n[1]}${n[0]}${n[2]}`,
          `${n[1]}${n[2]}${n[0]}`,
          `${n[2]}${n[0]}${n[1]}`,
          `${n[2]}${n[1]}${n[0]}`
        ])
        .forEach((n)=>{
          if(n!==number) {
            this.addNumber(n)
          }
        })
      })
      this.focusInputAmount()
    },
    toggleTripleNumber() {
      for(let i=0; i<=9; i++){
        this.addNumber(`${i}${i}${i}`)
      }
      this.focusInputAmount()
    },
    toggleRemove(index) {
      this.inputNumbers.splice(index, 1)
    },
    addBill() {
      console.log('add bill')

      /**
       * ยังไม่กรอกเลข
       */
      if(this.inputNumbers.length === 0)
        return Swal.fire({
          text: 'กรุณาใส่เลขที่จะเล่น',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.focusInputNumber()
        })

      /**
       * ไม่ใส่ราคา
       */
      if(!this.amountTop && !this.amountTod)
        return Swal.fire({
          text: 'กรุณาใส่ยอดเงินที่จะเล่น',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
        .then(()=>{
          this.focusInputAmount()
        })

      const maxTop = this.rate.betLimit.threeNumberTop.max
      const maxTod = this.rate.betLimit.threeNumberTode.max

      const amountTop = (this.amountTop || 0) //> maxTop ? maxTop : (this.amountTop || 0)
      const amountTod = (this.amountTod || 0) //> maxTod ? maxTod : (this.amountTod || 0)
      const billAmount = this.inputNumbers.reduce((total, number)=>{
        total += (amountTop + amountTod)
        return total
      }, 0)

      const bill = {
        type: 'threeNumber',
        text: {
          title: '3 ตัว',
          description: 'บน x โต๊ด',
          amount: `${amountTop} x ${amountTod}`
        },
        numbers: this.inputNumbers,
        amountTop: amountTop,
        amountTod: amountTod,
        billAmount: billAmount
      }
      this.$emit('addBill', bill)

      /**
       * reset ค่า
       */
      this.inputNumbers = []
      this.inputNumber = ''
      this.amountTop = ''
      this.amountTod = ''
      this.focusInputNumber()
    },
    clearNumbers() {
      this.inputNumbers = []
    },
    uniqueNumbers() {
      this.inputNumbers = _.uniq(this.inputNumbers)
    },
    keypress(event, pos) {
      // console.log(event.key)
      const focusable = this.elements
      const els = []
      focusable.forEach((el)=>{
        if(!el.disabled)
          els.push(el)
      })

      const cIndex = els.findIndex(el=>el===event.target)
      /**
       * กด Tab ในช่อง "ล่าง" ให้ใช้ฟังก์ชั่น addBill
       */
      if(event.key === 'Tab' && pos === 'bot') {
        event.preventDefault()
        this.addBill()
      }else // ไปข้างหน้า
      if(['Enter', 'ArrowRight', 'ArrowDown', 'Tab'].includes(event.key)) {
        const nIndex = cIndex + 1
        if(nIndex < els.length) {
          els[nIndex].focus()
          event.preventDefault()
        }
      }else // ย้อนกลับ
      if(['ArrowLeft', 'ArrowUp'].includes(event.key)) {
        const nIndex = cIndex - 1
        if(nIndex >= 0) {
          els[nIndex].focus()
          event.preventDefault()
        }
      }else
      if(event.code === 'Space') {
        this.toggleSpin()
        event.preventDefault()
      }else
      if( !['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'Backspace'].includes(event.key) && !['KeyV', 'ControlLeft', 'ControlRight'].includes(event.code) ) {
        event.preventDefault()
      }
    },
    focusInputNumber() {
      setTimeout(()=>{
        this.elements[0].focus()
      }, 500)
    },
    focusInputAmount() {
      setTimeout(()=>{
        this.elements[1].focus()
      }, 500)
    }
  },
  mounted() {
    this.elements = this.$refs.threeNumber.getElementsByClassName('express')
    this.focusInputNumber()
  }
}
</script>

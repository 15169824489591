<template>
  <b-card-body class="p-0">
    <div class="flag-bg p-3" :style="cssBackground">
      <h5>แทงเร็ว</h5>

      <h6 class="d-flex justify-content-between mb-3">
        <span>
          <span class="text-blue">[{{roundGroupName}}]</span>
          <span class="text-blue"> {{roundMarketName}}</span>
        </span>
        <span>{{roundDate}}</span>
      </h6>
      <div class="card-text pt-1 mb-3">
        <button
          class="btn m-1 border-warning"
          :class="[{'btn-warning font-weight-bold': inputMode==='twoNumber', 'alert-warning': inputMode!='twoNumber'}]"
          :disabled="!isOpenTwoTop && !isOpenTwoBot"
          @click="toggleMode('twoNumber')"
        >2 ตัว</button>

        <button
          class="btn m-1 border-warning"
          :class="[{'btn-warning font-weight-bold': inputMode==='threeNumber', 'alert-warning': inputMode!='threeNumber'}]"
          :disabled="!isOpenThreeTop && !isOpenThreeTod"
          @click="toggleMode('threeNumber')"
        >3 ตัว</button>

        <button
          class="btn m-1 border-warning"
          :class="[{'btn-warning font-weight-bold': inputMode==='threeSpin', 'alert-warning': inputMode!='threeSpin'}]"
          :disabled="!isOpenThreeTop"
          @click="toggleMode('threeSpin')"
        >6 กลับ</button>

        <button
          class="btn m-1 border-warning"
          :class="[{'btn-warning font-weight-bold': inputMode==='twoSpin', 'alert-warning': inputMode!='twoSpin'}]"
          :disabled="!isOpenTwoTop && !isOpenTwoBot"
          @click="toggleMode('twoSpin')"
        >19 ประตู</button>

        <button
          class="btn m-1 border-warning"
          :class="[{'btn-warning font-weight-bold': inputMode==='runNumber', 'alert-warning': inputMode!='runNumber'}]"
          :disabled="!isOpenRunTop && !isOpenRunBot"
          @click="toggleMode('runNumber')"
        >เลขวิ่ง</button>

        <button
          class="btn m-1 border-warning"
          :class="[{'btn-warning font-weight-bold': inputMode==='winNumber', 'alert-warning': inputMode!='winNumber'}]"
          @click="toggleMode('winNumber')"
        >วินเลข</button>

        <div v-if="roundIcon" class="float-right">
          <img :src="roundIcon" height="35" class="shadow-sm float-right">
        </div>
      </div>

      <TwoNumber
        v-if="inputMode==='twoNumber'"
        @inputNumbers="saveNumbers"
        @addBill="addBill"
      />
      <ThreeNumber
        v-if="inputMode==='threeNumber'"
        @inputNumbers="saveNumbers"
        @addBill="addBill"
      />
      <ThreeSpinNumber
        v-if="inputMode==='threeSpin'"
        @inputNumbers="saveNumbers"
        @addBill="addBill"
      />
      <TwoSpinNumber
        v-if="inputMode==='twoSpin'"
        @inputNumbers="saveNumbers"
        @addBill="addBill"
      />
      <RunNumber
        v-if="inputMode==='runNumber'"
        @inputNumbers="saveNumbers"
        @addBill="addBill"
      />
      <WinNumber
        v-if="inputMode==='winNumber'"
        @inputNumbers="saveNumbers"
        @addBill="addBill"
      />

      <table class="table mb-0">
        <tbody v-for="(bill, index) in bills">
          <tr class="border-info">
            <td class="text-center" style="width: 150px;">
              <div class="font-weight-light">{{ bill.text.title }}</div>
              <div class="font-weight-light">
                <span>{{ bill.text.description }}</span>
              </div>
              <div class="font-weight-bold">
                <span>{{ bill.text.amount }}</span>
              </div>
            </td>
            <td>
              <div class="d-flex flex-wrap w-100">
                <span
                  class="pr-2"
                  v-for="num in bill.numbers"
                  :class="numberStatusClass(bill, num)"
                >
                  {{num}}
                </span>
              </div>
            </td>
            <td class="text-center" style="width: 40px;">
              <span class="btn-delete" style="cursor: pointer;" @click="toggleRemoveBill(index)">
                <i class="far fa-trash-alt text-danger bigger-175"></i>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </b-card-body>
</template>
<script>
import Swal from 'sweetalert2'
import moment from '@/helpers/moment'

import TwoNumber from './TwoNumber'
import ThreeNumber from './ThreeNumber'
import ThreeSpinNumber from './ThreeSpinNumber'
import TwoSpinNumber from './TwoSpinNumber'
import RunNumber from './RunNumber'
import WinNumber from './WinNumber'

export default {
  name: 'ExpressForm',
  components: {
    TwoNumber,
    ThreeNumber,
    ThreeSpinNumber,
    TwoSpinNumber,
    RunNumber,
    WinNumber
  },
  props: ['round', 'soldout', 'bills'],
  data() {
    return {
      inputMode: 'twoNumber',
      inputNumbers: []
    }
  },
  computed: {
    roundGroupName() {
      if(!this.round)
        return ''

      return this.round?.note.groupTitle
    },
    roundMarketName() {
      if(!this.round)
        return ''

      return this.round?.note.marketTitle
    },
    roundDate() {
      if(!this.round)
        return ''

      return moment(this.round.roundDate.date).format("DD-MM-YYYY")
    },
    roundIcon() {
      if(!this.round)
        return false

      return this.round?.market?.imageIcon || false
    },
    cssBackground() {
      if(!this.round || !this.round?.market?.imageBg)
        return ''

      return {
        '--background-url': `url('${this.round.market.imageBg}')`
      }
    },
    rate() {
      return this.$store.state.round.rate
    },
    isOpenThreeTop() {
      return this.rate?.openBets.threeNumberTop.isAvailable || false
    },
    isOpenThreeTod() {
      return this.rate?.openBets.threeNumberTode.isAvailable || false
    },
    isOpenTwoTop() {
      return this.rate?.openBets.twoNumberTop.isAvailable || false
    },
    isOpenTwoBot() {
      return this.rate?.openBets.twoNumberBottom.isAvailable || false
    },
    isOpenRunTop() {
      return this.rate?.openBets.runTop.isAvailable || false
    },
    isOpenRunBot() {
      return this.rate?.openBets.runBottom.isAvailable || false
    },
    setNumbers() {
      return {
        threeNumberTop: {
          ...this.round?.setNumbers?.threeNumberTop,
          ...this.soldout?.threeNumberTop
        },
        threeNumberTode: {
          ...this.round?.setNumbers?.threeNumberTode,
          ...this.soldout?.threeNumberTode
        },
        twoNumberTop: {
          ...this.round?.setNumbers?.twoNumberTop,
          ...this.soldout?.twoNumberTop
        },
        twoNumberBottom: {
          ...this.round?.setNumbers?.twoNumberBottom,
          ...this.soldout?.twoNumberBottom
        },
        runTop: {
          ...this.round?.setNumbers?.runTop,
          ...this.soldout?.runTop
        },
        runBottom: {
          ...this.round?.setNumbers?.runBottom,
          ...this.soldout?.runBottom
        }
      }
    },
  },
  watch: {
    inputMode() {
      this.inputNumbers = []
    }
  },
  methods: {
    toggleMode(mode) {
      if(this.inputNumbers.length > 0) {
        Swal.fire({
          text: 'ข้อมูลที่ยังไม่บันทึกจะหายไปทันที กรุณายืนยันที่จะเปลี่ยนอีกครั้ง',
          icon: 'warning',
          confirmButtonText: 'ยืนยัน',
          confirmButtonColor: '#3085d6',
          showCancelButton: true,
          cancelButtonText: 'ยกเลิก'
        })
        .then((res)=>{
          if(res.isConfirmed) {
            this.inputMode = mode
          }
        })
      }else{
        this.inputMode = mode
      }
    },
    saveNumbers(numbers) {
      this.inputNumbers = numbers
    },
    addBill(bill) {
      this.bills.push(bill)
    },
    toggleRemoveBill(index) {
      this.bills.splice(index, 1)
    },
    numberStatusClass(bill, number) {

      let setTop = null
      let setBot = null
      if(bill.type === 'twoNumber') {
        setTop = this.setNumbers?.twoNumberTop?.[number]
        setBot = this.setNumbers?.twoNumberBottom?.[number]
      }else
      if(bill.type === 'threeNumber') {
        setTop = this.setNumbers?.threeNumberTop?.[number]
        setBot = this.setNumbers?.threeNumberTode?.[number]
      }else
      if(bill.type === 'runNumber') {
        setTop = this.setNumbers?.runTop?.[number]
        setBot = this.setNumbers?.runBottom?.[number]
      }

      if(setTop || setBot) {
        if((setTop === 's' && bill.amountTop > 0) || (setBot === 's' && bill.amountBot > 0)) {
          return 'num-closed text-danger'
        }else
        if((setTop === 'c' && bill.amountTop > 0) || (setBot === 'c' && bill.amountBot > 0)) {
          return 'num-closed text-danger'
        }else
        if((setTop === 'h' && bill.amountTop > 0) || (setBot === 'h' && bill.amountBot > 0)) {
          return 'num-cuted text-primary'
        }
      }

      return ''
    }
  }
}
</script>
<style lang="scss" scoped>
.num-closed {
  text-decoration: line-through;
}

.num-cuted {
  text-decoration: underline;
}

.flag-bg::before {
  content: "";
  background-repeat: repeat-y;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-size: 100% 100%;
  background-position: center;
  position: absolute;
  z-index: 0;
  background-image: var(--background-url);
  opacity: 0.12;
}

.flag-bg, .flag-bg div, .flag-bg h5, .flag-bg table {
  position: relative;
}

table {
  color: #000;
  border-spacing: 0px 10px;
  border-collapse: separate;

  tr {
    background-color: #FFF;
  }

  td {
    vertical-align: middle;
    border-top: 0px;
  }

  td:first-child {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-right: 1px solid #ccc;
  }

  td:last-child {
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
  }
}
</style>
